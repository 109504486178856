import { graphql, Link } from 'gatsby';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import React from 'react';

// Components
import { FluidImage } from 'components/PostCard/FluidImage';

// Layout
import { SEOContent } from 'layouts';

// Components
import {
  AuthorCard,
  Footer,
  OtherContentCard,
  PostContent,
  PostFullFooter,
  PostFullFooterRight,
  SiteNav,
  Wrapper,
} from 'components';
// @ts-ignore
import { Phone, TabletUp } from 'components/MediaQuery';
import { BurgerMenu } from 'components/SiteNav/BurgerMenu';

// Styles
import {
  DateDivider,
  inner,
  NoImage,
  outer,
  PostFull,
  PostFullHeader,
  PostFullImage,
  PostFullMeta,
  PostFullMetaDate,
  PostFullTitle,
  PostTemplate,
  ReadNextFeed,
  SiteHeader,
  SiteMain,
} from 'styles';


// Types
interface Props {
  data: {
    post: {
      title: string;
      tags: string[];
      created_date: string;
      markdown_content: string;
      author: string;
    };
  };
}

export default ({ data }: Props) => {
  const fluid = get(
    data,
    'post.data.attachments.localFiles[0].childImageSharp.fluid',
    {}
  );
  const title = get(data, 'post.data.title', '');
  const tags = get(data, 'post.data.tags', []);
  const createdDate = get(data, 'post.data.created_date', '');
  const markdown = get(data, 'post.data.markdown_content', '');
  const relatedPosts = get(data, 'relatedPosts', []);
  const author = get(data, 'post.data.author[0].data', {});

  return (
    <SEOContent title={title}>
      <Wrapper css={PostTemplate}>
        <header css={[outer, SiteHeader]}>
          <div css={inner}>
            <Phone>
              <BurgerMenu />
            </Phone>
            <TabletUp>
              <SiteNav isHome={false} />
            </TabletUp>
          </div>
        </header>
        <main css={[SiteMain, outer]}>
          <div css={inner}>
            <article css={[PostFull, !fluid && NoImage]}>
              <PostFullHeader>
                <PostFullMeta>
                  <PostFullMetaDate dateTime={createdDate}>
                    {createdDate}
                  </PostFullMetaDate>
                  {tags && tags.length && (
                    <>
                      <DateDivider>/</DateDivider>
                      <Link to={`/tags/${kebabCase(tags[0])}/`}>{tags[0]}</Link>
                    </>
                  )}
                </PostFullMeta>
                <PostFullTitle>{title}</PostFullTitle>
              </PostFullHeader>

              {fluid && (
                <PostFullImage>
                  <FluidImage alt={title} fluid={fluid} />
                </PostFullImage>
              )}
              <PostContent markdown={markdown} />

              <PostFullFooter>
                <AuthorCard author={author} />
                <PostFullFooterRight memberSlug={author.member_slug} />
              </PostFullFooter>
            </article>
          </div>
        </main>
        {/* Links to Previous/Next posts */}
        <aside className="read-next" css={outer}>
          <div css={inner}>
            <ReadNextFeed>
              {relatedPosts && (
                <OtherContentCard tags={tags} relatedContent={relatedPosts} />
              )}

              {/*{props.pageContext.prev && <PostCard post={props.pageContext.prev} />}*/}
              {/*{props.pageContext.next && <PostCard post={props.pageContext.next} />}*/}
            </ReadNextFeed>
          </div>
        </aside>
        <Footer />
      </Wrapper>
    </SEOContent>
  );
};

export const query = graphql`
  query($postId: Int) {
    post: airtable(table: { eq: "Posts" }, data: { post_id: { eq: $postId } }) {
      data {
        attachments {
          localFiles {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        created_date(formatString: "MM-DD-YYYY")
        markdown_content
        time_to_read
        title
        post_id
        tags
        author {
          data {
            member_slug
            bio
            full_name
            profile_picture {
              url
            }
          }
        }
      }
    }
    relatedPosts: allAirtable(
      filter: {
        table: { eq: "Posts" }
        data: { tags: { in: "Cross Country" }, post_id: { ne: $postId } }
      }
      limit: 3
    ) {
      totalCount
      nodes {
        data {
          title
          excerpt
          time_to_read
          post_id
          author {
            data {
              member_slug
              profile_picture {
                url
              }
            }
          }
        }
      }
    }
  }
`;
